module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.carbon8.org.au"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-143768861-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"837664883256844"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"carbon-eight","accessToken":"MC5YUW01Q0JJQUFLS2YwMzgx.PhFSbe-_vWtNMO-_ve-_ve-_ve-_vRLvv70e77-977-9R--_vVrvv73vv73vv73vv73vv73vv73vv73vv73vv73vv73vv73vv70","promptForAccessToken":true,"apiEndpoint":"https://carbon-eight.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Carbon8 » Regenerative Agriculture NFP","short_name":"Carbon8","description":"Carbon8 supports farmers to transition to regenerative agriculture by assisting them in rebuilding the carbon (organic matter) in their soil.","start_url":"/","background_color":"#EBEDF2","theme_color":"#3D63AE","display":"standalone","icon":"src/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5d8f06ff4f49f912eadf86e5540e4f56"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-DNYPG9M9JB"],"gtagConfig":{"debug_mode":true,"anonymize_ip":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
